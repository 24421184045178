import styled from 'styled-components';

export const StatusWrapper = styled.div`

    display: flex;
    align-items: center;
    gap: 5px;
    background: #e6e6e6;
    padding-left: 16px;

    .status {
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }

    .status.status-online {
        background: var(--avaya_teal);
        animation: glow 4s ease-in-out infinite;
    }

    @keyframes glow {
        0%, 100% {
            background-color: var(--avaya_teal);
        }
        50% {
            background-color: var(--cct_turquoise);
        }
    }

    .status.status-offline {
        background: #ababab;
    }

    .title {
        font-family: var(--font_family);
        font-size: var(--font_size_normal);
        color: #6f6f6f;
    }
`;
