import styled from 'styled-components';

export const ToggleWrapper = styled.div`
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        width: 56px;
        height: 56px;
        padding-bottom: 4px;
        background-color: var(--avaya_red);
        color: white;
        position: relative;
        font-size: 32px;
        fill: white;
        box-shadow: rgba(151, 124, 156, 0.2) 0px 5px 18px 0px, rgba(203, 195, 212, 0.2) 0px 5px 32px 0px, rgba(216, 212, 221, 0.1) 0px 8px 58px 0px;
    }

    button:hover {
        background-image: linear-gradient(185deg, rgb(182, 42, 32), rgb(172, 32, 22));
    }
`;
