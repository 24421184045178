import styled from 'styled-components';

export const MessageOptionsWrapper = styled.div`
    .message-body-options {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .message-body-options .message-buttons {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }

    .message-body-options .message-buttons button {
        border: 1px solid var(--avaya_teal);
        border-radius: 7px;
        color: var(--avaya_teal);
        background-color: var(--avaya_grey_light);
        font-family: var(--font_family);
        font-size: var(--font_size_normal);

        padding: 4px 16px;
        min-height: 40px;
        min-width: 40px;
        cursor: pointer;
        outline: none;
    }

    .message-body-options .message-buttons button:not(:disabled) {
        cursor: pointer;
    }

    .message-body-options .message-buttons button:disabled {
        pointer-events: none;
        color: #ababab;
        border-color: #e6e6e6;
        background-color: #e6e6e6;
    }

    .message-body-options .message-buttons button:not(:disabled):hover {
        color: white;
        background-color: var(--avaya_teal);
        border-color: var(--avaya_teal);
        transition: .25s ease-out;
    }
`;
