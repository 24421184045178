import { MSGPartyType, MSGNewContextType } from '../models/msg';
import { SDK, SDKEventPayload, SDKMessage, SDKUserContext, SDKStage, SDKStageType, SDKEngagementStatus } from '../models/sdk';
import { UserContextType } from '../models/user';
import { getConfig, getParams } from './config';
import getAuth from './auth';
import firestore from './firestore';
import { ProcessingContextType } from '../models/processing';
import { EngagementContextType } from '../models/engagement';
import { GENERAL_TIMEOUT, MESSAGE_FAILED_ENGAGEMENT, MESSAGE_FAILURE, MESSAGE_UNAUTHORIZED_USER, MESSAGE_UNAUTHORIZED_SYSTEM } from '../constants';
import { StatusContextType } from '../models/status';
import { AuthResponse, AuthResponseJwt } from '../models/auth';
import config from '../config/config';

declare const AvayaCCaaSChatSDK: SDK;

let isBusy = false;

let attempt = 1
let maxAttempts = 5
let isInError = false;
const BOT_NAME = config().bot.name;
const env = config().env
const default_queue = config().default_queue
let engagementId = ''
const conversation = {
    hasStarted: false,
    start: (statusCtxt: StatusContextType | undefined) => {
        statusCtxt?.setIsOnline(true);
        conversation.hasStarted = true;
    },
    end: (statusCtxt: StatusContextType | undefined) => {
        statusCtxt?.setIsOnline(false);
        conversation.hasStarted = false;
    }
}



// STAGES



/**
 * Keeps track of initialization / shutdown processing stage of SDK.
 * */
export const stage: SDKStage = {
    state: SDKStageType.IDLE,
    setState: function (state: SDKStageType) {
        this.state = state;
    },
    ongoing: null,
    setExpectation: function (state: SDKStageType) {
        this.ongoing = this.state;
        this.state = state;
    },
    clearExpectation: function () {
        if (!this.ongoing) return;

        this.state = this.ongoing;
        this.ongoing = null;
    },
};

/**
 * Determines if SDK processing is out of sync.
 * */
function isInterrupted(process: string, state: SDKStageType): boolean {
    const isInterrupted = state !== stage.state;
    if (isInterrupted) console.warn(`Warning: SDK ${process} was interruped by another process:`, stage.state);
    return isInterrupted;
}

/**
 * Determines if SDK is processing shutdown.
 * */
function isShutting(): boolean {
    return [
        SDKStageType.SHUT_PENDING,
        SDKStageType.SHUT_STARTED,
        SDKStageType.SHUT_ENGAGEMENT,
        SDKStageType.SHUT_ENGAGEMENT_DISSCONNECTED,
        SDKStageType.SHUT_REQUESTED,
        SDKStageType.SHUT_EXECUTED
    ].includes(stage.state);
}

/**
 * Determines if SDK was initialized or processing initialization.
 * */
function isRunning(): boolean {
    return [
        SDKStageType.INIT_STARTED,
        SDKStageType.INIT_ENGAGEMENT,
        SDKStageType.INIT_ENGAGEMENT_CREATED,
        SDKStageType.INIT_REQUESTED,
        SDKStageType.INIT_EXECUTED,
        SDKStageType.INIT_FINISHED
    ].includes(stage.state);
}

/**
 * Determines if SDK shutdown is finished.
 * */
function isReadyForInit(): boolean {
    return [
        SDKStageType.IDLE,
        SDKStageType.INIT_FAILED,
        SDKStageType.SHUT_FINISHED,
        SDKStageType.SHUT_FAILED
    ].includes(stage.state);
}
function isRetryAllowed(): boolean {
    return [
        SDKStageType.INIT_PENDING,
        SDKStageType.INIT_STARTED,
        SDKStageType.INIT_ENGAGEMENT,
        SDKStageType.INIT_ENGAGEMENT_CREATED,
        SDKStageType.INIT_REQUESTED,
        SDKStageType.INIT_EXECUTED,
        SDKStageType.INIT_FINISHED,
        SDKStageType.INIT_FAILED,

    ].includes(stage.state)
}
/**
 * Determines if SDK initialization is finished.
 * */
function isReadyForShutdown(): boolean {
    return [
        SDKStageType.INIT_FINISHED,
        SDKStageType.INIT_FAILED
    ].includes(stage.state);
}

/**
 * Determines if SDK is not initialized.
 * */
function isNotInitialized(): boolean {
    return [
        SDKStageType.IDLE,
        SDKStageType.AUTH_DENIED,
        SDKStageType.AUTH_INTERRUPTED,
        SDKStageType.INIT_FAILED,
        SDKStageType.SHUT_FINISHED
    ].includes(stage.state);
}

/**
 * Determines if SDK authentification started.
 * */
function isAuthStarted(): boolean {
    return [
        SDKStageType.AUTH_STARTED
    ].includes(stage.state);
}


// PROCESSES


let pedningTimers: Array<NodeJS.Timeout> = [];
let pedningProcesses: Array<(value: void) => void> = [];

/**
 * Discards pending processes if any.
 * */
function discardPendingProcessesOf(states: Array<SDKStageType>) {
    if (!pedningProcesses.length || !states.includes(stage.state)) return;

    console.debug('SDK pending processes discarding:', states, stage.state);
    pedningTimers.forEach(timerId => {
        clearTimeout(timerId);
    });
    pedningProcesses.forEach(resolve => {
        resolve();
    });
    pedningProcesses.splice(0, pedningProcesses.length);
    stage.setState(SDKStageType.IDLE);
    console.debug('SDK pending processes discarded', stage.state);
}

/**
 * Shuts down SDK.
 * If SDK is processing initialization shutdown will be executed ONLY after that.
 * Shutdown will be ignored if SDK isn't initialized.
 * */
export async function shutdownSDK(
    processingCtxt: ProcessingContextType | undefined,
    statusCtxt: StatusContextType | undefined,
    newMsgCtxt: MSGNewContextType | undefined,
    engagementCtxt: EngagementContextType | undefined,
    isClosed = false,
) {

    let seconds = 0;

    function onNotInitialized() {
        console.debug('SDK was not initialized', stage.state);
        stage.setState(SDKStageType.SHUT_FINISHED);
        console.debug('SDK shutdown finished:', stage.state);
        newMsgCtxt?.clearDialog();
    };

    if (isNotInitialized()) {
        onNotInitialized();
        return;
    }

    discardPendingProcessesOf([SDKStageType.SHUT_PENDING, SDKStageType.INIT_PENDING]);

    console.debug('SDK shutdown queued:', stage.state);

    async function waitFinishInit(resolve: (value: void) => void) {

        // CHECKUP

        if (!processingCtxt) throw new Error('No processing context');
        if (!statusCtxt) throw new Error('No status context');
        if (!engagementCtxt) throw new Error('No engagement context');
        if (!newMsgCtxt) throw new Error('No new message context');

        // ACTIONS
        if (SDKStageType.SHUT_EXPECTED === stage.state) {
            shutdownUi(processingCtxt, statusCtxt, newMsgCtxt, 'Expecting ', false);
            return resolve();
        }

        if (isNotInitialized()) {
            onNotInitialized();
            newMsgCtxt?.addMessage('Shutdown attempt failed:\nSDK was not initialized.', MSGPartyType.UI);
            return resolve();
        }

        if (SDKStageType.INIT_PENDING === stage.state) {
            console.debug('SDK shutdown was interrupted by initialization', stage.state);
            return resolve();
        }

        if (isReadyForShutdown()) {
            await shutdownSession(processingCtxt, statusCtxt, newMsgCtxt, engagementCtxt, isClosed);
            return resolve();
        }
        else {
            console.debug('SDK shutdown delayed:', stage.state);
            seconds++;
            if (seconds * 1000 > GENERAL_TIMEOUT) {
                discardPendingProcessesOf([SDKStageType.SHUT_PENDING]);
                newMsgCtxt?.addMessage('Shutdown failed:\nSDK was not initialized.', MSGPartyType.UI);
                return;
            }

            stage.setState(SDKStageType.SHUT_PENDING);
            const timerId = setTimeout(() => waitFinishInit(resolve), 400);
            pedningTimers.push(timerId);
            pedningProcesses.push(resolve);
        }
    }

    return new Promise(waitFinishInit);
}

/**
 * Disconnects engagement if any and shuts down SDK.
 * */
async function shutdownSession(
    processingCtxt: ProcessingContextType,
    statusCtxt: StatusContextType,
    newMsgCtxt: MSGNewContextType,
    engagementCtxt: EngagementContextType,
    isClosed: boolean,
) {

    if (isShutting()) {
        console.warn(`Warning: Duplicate attempt to shutdown SDK:`, stage.state);
        return;
    }

    discardPendingProcessesOf([SDKStageType.INIT_PENDING]);

    console.debug('SDK shutdown initiated:', stage.state);

    stage.setState(SDKStageType.SHUT_STARTED);

    // DISCONNECT ENGAGEMENT

    console.debug('SDK engagement disconnection requested:', stage.state);

    stage.setState(SDKStageType.SHUT_ENGAGEMENT);

    try {
        let engagement = engagementCtxt.engagement
        if (!engagement) {
            engagement = AvayaCCaaSChatSDK.getEngagementById(engagementId)
        }
        let hasActive = (engagement?.engagementStatus === SDKEngagementStatus.ACTIVE)
        if (hasActive) await engagement?.disconnect();
        else newMsgCtxt.addMessage(`No active engagement.`, MSGPartyType.UI, true);
        console.debug('SDK engagement disconnected:', stage.state);
    } catch (e) {
        console.warn('SDK got an exception on engagement disconnect', stage.state, e);
    }

    // if (isInterrupted('shutdown', SDKStageType.SHUT_ENGAGEMENT)) return;

    engagementCtxt.setEngagement(undefined);
    stage.setState(SDKStageType.SHUT_ENGAGEMENT_DISSCONNECTED);

    // SHUTDOWN SDK

    console.debug('SDK shutdown requested:', stage.state);

    try {
        stage.setState(SDKStageType.SHUT_REQUESTED);
        await AvayaCCaaSChatSDK.shutdown();
        stage.setState(SDKStageType.SHUT_EXECUTED);
    } catch {
        console.error('Exception in SDK Shutdown');
        stage.setState(SDKStageType.SHUT_FAILED);
    }

    isClosed && newMsgCtxt.clearDialog();
    shutdownUi(processingCtxt, statusCtxt, newMsgCtxt);
}

/**
 * Locks UI and notifys user on SDK shutdown.
 * */
function shutdownUi(
    processingCtxt: ProcessingContextType,
    statusCtxt: StatusContextType,
    newMsgCtxt: MSGNewContextType,
    prefix: string = '',
    isDone: boolean = true,
) {
    console.debug(prefix + 'SDK shutdown processed:', stage.state);

    newMsgCtxt.addMessage('Current session was terminated.', MSGPartyType.UI, true);
    processingCtxt.setIsFrozen(true);
    stage.setState(SDKStageType.SHUT_FINISHED);
    engagementId = ""
    if (isDone) {
        conversation.end(statusCtxt);
        statusCtxt?.setAgent(undefined);
        processingCtxt?.setNotification({ message: '' });
        clearSDKListeners();
    };

    console.debug(prefix + 'SDK shutdown finished:', stage.state);
}



// INITILIAZE



/**
 * Initializes SDK.
 * If SDK is processing shutdown initialization will be executed ONLY after that.
 * */
export async function initializeSDK(
    user: UserContextType | undefined,
    processingCtxt: ProcessingContextType | undefined,
    statusCtxt: StatusContextType | undefined,
    newMsgCtxt: MSGNewContextType | undefined,
    engagementCtxt: EngagementContextType | undefined,
) {

    let seconds = 0;

    if (SDKStageType.INIT_FINISHED === stage.state) {
        console.debug('SDK is already initialized', stage.state);
        return;
    }

    discardPendingProcessesOf([SDKStageType.SHUT_PENDING, SDKStageType.INIT_PENDING]);

    console.debug('SDK init queued:', stage.state);
    

    async function waitFinishShutdown(resolve: (value: void) => void) {

        // CHECKUP

        if (!user) throw new Error('No user context');
        if (!processingCtxt) throw new Error('No processing context');
        if (!statusCtxt) throw new Error('No status context');
        if (!engagementCtxt) throw new Error('No engagement context');
        if (!newMsgCtxt) throw new Error('No new message context');

        // ACTIONS

        if (SDKStageType.AUTH_DENIED === stage.state) {
            console.debug('SDK is not authorized', stage.state);
            resolve();
            return;
        }

        if (SDKStageType.INIT_FINISHED === stage.state) {
            console.warn('SDK is already initialized', stage.state);
            resolve();
            return;
        }

        if (SDKStageType.SHUT_PENDING === stage.state) {
            console.warn('SDK initialization was interrupted by shutdown', stage.state);
            resolve();
            return;
        }

        if (isReadyForInit()) {
            statusCtxt?.setAgent(undefined);
            await initializeSession(user, processingCtxt, statusCtxt, newMsgCtxt, engagementCtxt);
            resolve();
        }
        else {
            console.debug('SDK init delayed:', stage.state);
            seconds++;
            if (seconds * 1000 > GENERAL_TIMEOUT) {
                discardPendingProcessesOf([SDKStageType.INIT_PENDING]);
                newMsgCtxt?.addMessage('Initialization failed:\nSDK was not shutdown.', MSGPartyType.UI);
                return;
            }

            stage.setState(SDKStageType.INIT_PENDING);
            const timerId = setTimeout(() => waitFinishShutdown(resolve), 400);
            pedningTimers.push(timerId);
            pedningProcesses.push(resolve);
        }
    }

    return new Promise(waitFinishShutdown);
}

/**
 * Clears all SDK listeners set by initializeSession().
 * */
function clearSDKListeners() {
    const listeners = [
        'INITIALIZED',
        'TOKEN_EXPIRY_REMINDER',
        'TOKEN_EXPIRED',
        'MESSAGE_ARRIVED',
        'MESSAGE_DELIVERED',
        'EVENT_STREAM_CONNECTING',
        'EVENT_STREAM_CONNECTED',
        'EVENT_STREAM_CLOSED',
        'EVENT_STREAM_FAILED',
        'IDLE_TIMEOUT_EXPIRED',
        'PARTICIPANT_ADDED',
        'PARTICIPANT_DISCONNECTED',
        'ENGAGEMENT_ACTIVE',
        'ENGAGEMENT_ERROR',
        'ENGAGEMENT_TERMINATED',
        'SHUTDOWN'
    ];
    listeners.forEach(listener => {
        AvayaCCaaSChatSDK.off(listener);
    });
}

/**
 * Authorizes user and initializes SDK.
 * Executed ONLY if SDK wasn't initialized and not in authorization or initialization process.
 * */
async function initializeSession(
    user: UserContextType,
    processingCtxt: ProcessingContextType,
    statusCtxt: StatusContextType,
    newMsgCtxt: MSGNewContextType,
    engagementCtxt: EngagementContextType,
) {
    let initTimerId: NodeJS.Timeout;
    // Window Events 
    async function beforeunload (e:any) {
        e.preventDefault();
        await shutdownSession(processingCtxt , statusCtxt, newMsgCtxt, engagementCtxt, false);
        return 'Are you sure you want to leave?';
    };
    window.addEventListener('beforeunload', beforeunload );
    // CONTEXTS

    const setIsBusy = processingCtxt.setIsBusy;
    const setIsFrozen = processingCtxt.setIsFrozen;
    const addMessage = newMsgCtxt.addMessage;
    const removeTempMessage = newMsgCtxt.removeTempMessage;
    const addRichMessage = newMsgCtxt.addRichMessage;
    const updateParticipant = newMsgCtxt.updateParticipant;
    const setEngagement = engagementCtxt.setEngagement;

    // ACTIONS

    function setBusy(state: boolean) {
        isBusy = state;
        setIsBusy(state);
    }

    function onFailure(message: string, state = SDKStageType.INIT_FAILED) {
        console.warn(`Warning: SDK process failed:`, message);
        addMessage(message, MSGPartyType.UI);
        setBusy(false);
        setIsFrozen(true);
        stage.setState(state);
        return;
    }

    // PROCESS

    if (isRunning()) {
        console.warn(`Warning: Duplicate attempt to initialize SDK:`, stage.state);
        return;
    }

    discardPendingProcessesOf([SDKStageType.SHUT_PENDING]);

    console.debug('SDK init initiated:', stage.state);

    setBusy(true);
    setIsFrozen(false);
    // ToDo: remove incoming mock data
    // addRichMessage(mockIncomingData[1], 'AGENT');

    try {
        // AUTHORIZE USER

        if (!user) return onFailure(MESSAGE_UNAUTHORIZED_USER, SDKStageType.AUTH_DENIED);

        console.debug('SDK authorizing:', stage.state);

        if (isAuthStarted()) {
            console.warn(`Warning: Duplicate attempt to authorize user:`, stage.state);
            return;
        }

        addMessage(`Authorizing a new session...`, MSGPartyType.UI, true);

        stage.setState(SDKStageType.AUTH_STARTED);
        const { jwt, integrationId } = await authentificate();
        if (!jwt || !integrationId) return;

        console.debug(`Recieved\njwt:\n${jwt}\nintegrationId:\n${integrationId}`);

        if (isShutting()) {
            console.warn(`Warning: SDK authorization was interruped by shutdown:`, stage.state);
            stage.setState(SDKStageType.AUTH_INTERRUPTED);
            return;
        }

        if (isInterrupted('authorization', SDKStageType.AUTH_STARTED)) return;

        console.debug('SDK authorized:', stage.state);
        stage.setState(SDKStageType.AUTH_FINISHED);

        // INITIALIZE SDK

        AvayaCCaaSChatSDK.on('INITIALIZED', async (userContext: SDKUserContext) => {
            console.debug('SDK init responded', stage.state);

            stage.setState(SDKStageType.INIT_ENGAGEMENT);
            const created = await AvayaCCaaSChatSDK.createEngagement({
                user_first_name: user.firstName,
                user_last_name: user.lastName,
                user_email: user.emailId,
                companyId: user.companyId, 
                default_queue: default_queue,
                environment: env,
            });
            stage.setState(SDKStageType.INIT_ENGAGEMENT_CREATED);
            if (engagementId === "") engagementId = created.engagementId;
            clearTimeout(initTimerId);

            if (created) {
                console.debug('SDK engagement created:', created);
                addMessage('New session is waiting for the bot...', MSGPartyType.UI, true);
                setIsFrozen(false);
                setEngagement(created);
                stage.setState(SDKStageType.INIT_FINISHED);
                console.debug('SDK init finished:', stage.state);

                firestore(user, created.engagementId);
            }
            else {
                console.error(MESSAGE_FAILED_ENGAGEMENT);
                onFailure(MESSAGE_FAILED_ENGAGEMENT);
            }
        })

        console.debug('SDK init requested:', stage.state);

        addMessage(`Initializing a new session...`, MSGPartyType.UI, true);
        stage.setState(SDKStageType.INIT_STARTED);

        stage.setState(SDKStageType.INIT_REQUESTED);
        await AvayaCCaaSChatSDK.init(getParams(jwt, integrationId), getConfig());
        stage.setState(SDKStageType.INIT_EXECUTED);

        console.debug('SDK init executed:', stage.state);

        // ToDo: check if this timer has an effect if init is interrupted
        initTimerId = setTimeout(() => {
            onFailure(MESSAGE_FAILURE);
        }, GENERAL_TIMEOUT);

        // TOKEN EVENTS

        async function refreshJWT() {
            if (!user) return onFailure(MESSAGE_UNAUTHORIZED_USER, SDKStageType.AUTH_DENIED);

            const { jwt } = await authentificate();
            if (!jwt) return;

            AvayaCCaaSChatSDK.setJwtToken(jwt);
        }

        AvayaCCaaSChatSDK.on('TOKEN_EXPIRY_REMINDER', refreshJWT);

        AvayaCCaaSChatSDK.on('TOKEN_EXPIRED', refreshJWT);

        async function authentificate(): Promise<AuthResponseJwt> {
            const response = await getAuth(user);
            response.displayMessage && onFailure(response.displayMessage, SDKStageType.AUTH_DENIED);
            if (isAuthJwt(response)) return response;
            else return {
                jwt: '',
                integrationId: '',
                ttl: 0,
                displayMessage: response.displayMessage
            };
        }

        function isAuthJwt(response: AuthResponse): response is AuthResponseJwt {
            return Object.keys(response).includes('jwt');
        }

        // MESSAGE EVENTS

        function updateConversationStatus() {
            if (conversation.hasStarted) return;

            conversation.start(statusCtxt);
            newMsgCtxt.clearDialog(true);
            setBusy(false);
        }
        
        async function validateEngagement(engagementId:string){
            const engagement = await AvayaCCaaSChatSDK.getEngagementById(engagementId)
            if (!engagement) await shutdownSDK(processingCtxt, statusCtxt, newMsgCtxt, engagementCtxt, false);
        }

        AvayaCCaaSChatSDK.on('MESSAGE_ARRIVED',async (message: SDKMessage) => {
            console.log('Message ARRIVED:', message);
            engagementId = message.engagementId
            updateConversationStatus();
            const participantType = message.senderParticipant.participantType;
            if ('SYSTEM' === participantType) addRichMessage(message);
            if ('AGENT' === participantType) addMessage(message.body.elementText.text, participantType, false, message.senderParticipant.displayName);
            // ToDo check message.senderParticipant.displayName (it's empty for SYSTEM)
            validateEngagement(engagementId)
        });

        AvayaCCaaSChatSDK.on('MESSAGE_DELIVERED', (message: SDKMessage) => {
            console.log('Message DELIVERED:', message);
            const participantType = message.senderParticipant.participantType;
            if ('CUSTOMER' === participantType) addMessage(message.body.elementText.text, participantType, false, message.senderParticipant.displayName);
            // ToDo check message.senderParticipant.displayName
        });

        // SESSION EVENTS
        AvayaCCaaSChatSDK.on('EVENT_STREAM_CONNECTING', (userContext: SDKUserContext) => {
            console.log('EVENT_STREAM_CONNECTING', userContext)
        })

        AvayaCCaaSChatSDK.on('EVENT_STREAM_CONNECTED', (userContext: SDKUserContext) => {
            console.log('EVENT_STREAM_CONNECTED', userContext)
            setIsBusy(false)
            if (isInError) {
                removeTempMessage()
                isInError = false
            }
            attempt = 0
        })

        AvayaCCaaSChatSDK.on('EVENT_STREAM_CLOSED', (userContext: SDKUserContext) => {
            console.log('EVENT_STREAM_CLOSED', userContext)
        })

        AvayaCCaaSChatSDK.on('EVENT_STREAM_FAILED', async (eventPayload: SDKEventPayload) => {
            console.error(`EVENT_STREAM_FAILED: ${JSON.stringify(eventPayload)}\n STATE: ${stage.state}`)
            if (SDKStageType.SHUT_EXPECTED === stage.state) {
                stage.clearExpectation();
                processingCtxt?.setNotification({ message: '' });
            } else {
                console.log("Trying to connect...")
                addMessage(`Trying to connect...`, MSGPartyType.UI, true);
                isInError = true
                if (isRetryAllowed() && attempt <= maxAttempts) {
                    setIsBusy(true)
                    console.log(`Trying to connect... attempt ${attempt + 1}/${maxAttempts}`)
                    addMessage(`Trying to connect... attempt ${attempt + 1}/${maxAttempts}`, MSGPartyType.UI, true);
                    attempt += 1
                    AvayaCCaaSChatSDK.retryConnection()
                }
                if (attempt > maxAttempts) {
                    addMessage(`Something went wrong: Please verify your internet connection and refresh your browser`, MSGPartyType.UI, true);

                }

            }
        });

        AvayaCCaaSChatSDK.on('IDLE_TIMEOUT_EXPIRED', (eventPayload: SDKEventPayload) => {
            console.log('IDLE_TIMEOUT_EXPIRED');
            const gracePeriod = eventPayload.gracePeriod;
            processingCtxt?.setNotification({
                message: `If there are no new messages the chat session will expire in ${gracePeriod} seconds!`,
                close: {
                    auto: gracePeriod,
                    onAuto: async (left: number) => {
                        addMessage('Current session expired.', MSGPartyType.UI);
                        addMessage('Shutting down...', MSGPartyType.UI, true);
                        await shutdownSession(processingCtxt, statusCtxt, newMsgCtxt, engagementCtxt, false);
                    },
                    onManual: (left: number) => {
                        stage.setExpectation(SDKStageType.SHUT_EXPECTED);
                        addMessage(`Please, reply.\nOtherwise session will expire in ${left} seconds.`, MSGPartyType.UI);
                    },
                    onInterrupt: (left: number) => {
                        stage.setExpectation(SDKStageType.SHUT_EXPECTED);
                        addMessage(`Please, reply.\nOtherwise session will expire in ${left} seconds.`, MSGPartyType.UI);
                    }
                }
            });
        });


        // PARTICIPANT EVENTS

        AvayaCCaaSChatSDK.on('PARTICIPANT_ADDED', async (userContext: SDKUserContext) => {
            console.log('PARTICIPANT_ADDED', userContext);
            if ('AGENT' === userContext.participant.participantType) updateParticipant(userContext, true);
            // ToDo check userContext: SDKUserContext.participant.displayName
            let engagementsTest = await AvayaCCaaSChatSDK.getEngagements({queue:true})
            let engagementTest = {}
            if(engagementsTest[0])  engagementTest = await AvayaCCaaSChatSDK.getEngagementById(engagementsTest[0].engagementId)
            console.log(engagementTest)
        });

        AvayaCCaaSChatSDK.on('PARTICIPANT_DISCONNECTED', (userContext: SDKUserContext) => {
            console.log('PARTICIPANT_DISCONNECTED', userContext);
            if ('AGENT' === userContext.participant.participantType) updateParticipant(userContext, false);
            if ('SYSTEM' === userContext.participant.participantType) {
                if (isBusy) {
                    // ToDo: check if system droppes when agent joins and how to handle it
                    const userCtxt: SDKUserContext = { ...userContext };
                    userCtxt.participant.displayName = BOT_NAME;
                    updateParticipant(userCtxt, false);
                    addMessage('System disengaged.', MSGPartyType.UI);
                    console.error('System disengaged');
                    setIsFrozen(true);
                    setBusy(false);
                }
                else addMessage('Connecting...', MSGPartyType.UI, true);
            }
        });

        // ENGAGEMENT EVENTS

        AvayaCCaaSChatSDK.on('ENGAGEMENT_ACTIVE', (userContext: SDKUserContext) => {
            console.log('ENGAGEMENT_ACTIVE');
        });

        AvayaCCaaSChatSDK.on('ENGAGEMENT_ERROR', (userContext: SDKUserContext) => {
            console.log('ENGAGEMENT_ERROR');
        });

        AvayaCCaaSChatSDK.on('ENGAGEMENT_TERMINATED', async (userContext: SDKUserContext) => {
            console.log('ENGAGEMENT_TERMINATED');
            onAutoShutdown();
            await shutdownSDK(processingCtxt, statusCtxt, newMsgCtxt, engagementCtxt, false);
        });

        function onAutoShutdown() {
            discardPendingProcessesOf([SDKStageType.INIT_PENDING, SDKStageType.SHUT_PENDING]);
        }

        AvayaCCaaSChatSDK.on('SHUTDOWN', (eventPayload: SDKEventPayload) => {
            console.log('SHUTDOWN');
            if (SDKStageType.SHUT_REQUESTED === stage.state) return;

            onAutoShutdown();
            shutdownUi(processingCtxt, statusCtxt, newMsgCtxt, 'Auto ');
        });
    } catch (err: any) {
        const error = typeof err === 'object' ? JSON.stringify(err) : err,
            msg = error.match(/ACS-\d*/),
            code = msg ? msg[0] : 'NONE';
        switch (code) {
            case 'ACS-204': console.warn(`Warning: Duplicate attempt to initialize SDK:\n${error}`);
                console.warn('SDK ACS-204:', stage.state);
                await AvayaCCaaSChatSDK.shutdown();
                shutdownUi(processingCtxt, statusCtxt, newMsgCtxt)
                addMessage('Restarting...', MSGPartyType.UI, true)
                cleanSessionStorage();
                initializeSession(user, processingCtxt, statusCtxt, newMsgCtxt, engagementCtxt);
                break;

            case 'ACS-302': console.error(`Error: User is not logged in:\n${error}`);
                onFailure(MESSAGE_UNAUTHORIZED_USER, SDKStageType.AUTH_DENIED);
                break;

            case 'ACS-303':
                console.warn(`Error: Invalid configuration values: \n${error}`);
                break;
                
            case 'ACS-304': console.warn(`Warning: Server responded unexpectedly:\n${error}`);
                onFailure(MESSAGE_FAILURE);
                if (sessionStorage.ccaasActiveEngagements || sessionStorage.ccaasClientSessionId) {
                    console.warn(`Warning: SDK session / engagements expired.`);
                    cleanSessionStorage()
                    console.warn(`Warning: SDK engagement cache cleared.`);
                    addMessage('Cleared cache.', MSGPartyType.UI);
                    addMessage('Reattempting...', MSGPartyType.UI, true);
                    initializeSession(user, processingCtxt, statusCtxt, newMsgCtxt, engagementCtxt);
                }
                break;

            case 'ACS-305': console.error(`Error: System is not authorized:\n${error}`);
                onFailure(MESSAGE_UNAUTHORIZED_SYSTEM, stage.state);
                break;
            
            case 'ACS-321': console.warn(`Warning: SDK init/shutdown is in progress:\n${error}`);
                console.warn('SDK ACS-321:', stage.state);
                break;

            default:
                console.error(`Error: Failed to initialize SDK:\n${error}`);
                onFailure(MESSAGE_FAILURE);
                break;
        }
    }
}

export default initializeSDK;

function cleanSessionStorage() {
    sessionStorage.removeItem('ccaasActiveEngagements');
    sessionStorage.removeItem('ccaasClientSessionId');
}

