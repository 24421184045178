import config from "../config/config";
import { FirestoreResponse } from "../models/firestore";
import { UserContextType, FirestoreUserData } from "../models/user";
import { getOptions_POST, parseResponse } from "./http";

function getBody(user: UserContextType, engagementId: string): FirestoreUserData {
    return {
        engagementId: engagementId,
        companyId: user.companyId,
        firstName: user.firstName,
        lastName: user.lastName,
        userEmail: user.emailId,
    };
}

export default async function firestore(user: UserContextType, engagementId: string): Promise<FirestoreResponse> {
    try {
        console.debug(`FIRESTORE requested...`);
        const response = await fetch(config().url.api.firestore, getOptions_POST( getBody(user, engagementId) ));
        return parseResponse('FIRESTORE', response);
    } catch (error: any) {
        console.error(`Failed to retrieve FIRESTORE data`);
        throw error;
    }
}