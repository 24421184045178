import React from 'react';
import { useProcessingContext } from '../../../contexts/processing';
import { useActionsMenuContext } from '../../../contexts/actions-menu';

function ActionsMenuOpen() {
   const menuCtxt = useActionsMenuContext();
   const processingCtxt = useProcessingContext();

   function openMenu() {
      if (isDisabled()) return;

      menuCtxt?.setIsOpen(true);
   }

   function isDisabled() {
      return processingCtxt?.isBusy;
   }

   return (
      <button type='button' className='' aria-label='Open action menu' aria-expanded='false' disabled={ isDisabled() } onClick={openMenu}>
         <svg width='24' height='24' viewBox='0 0 24 24'>
            <path d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z'></path>
         </svg>
      </button>
   );
};

export default ActionsMenuOpen;
