import React from 'react';
import { useActionsMenuContext } from '../../../contexts/actions-menu';

function ActionsMenuClose() {
   const menuCtxt = useActionsMenuContext();

   return (
      <button title='Return' type='button' aria-label='Return' className='neo-icon-redirect' onClick={() => menuCtxt?.setIsOpen(false)}></button>
   );
};

export default ActionsMenuClose;
