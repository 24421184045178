import React from 'react';
import PropTypes from 'prop-types';
import { MessageOptionsWrapper } from './message-options.styled';
import { useProcessingContext } from '../../../contexts/processing';
import { useMsgContext, useNewMsgContext } from '../../../contexts/msg';
import LinkifiedText from '../content/linkified-text';
import { SDKParticipantType } from '../../../models/sdk';

function MessageOptions(props) {

   const isBusy = useProcessingContext().isBusy;
   const isFrozen = useProcessingContext().isFrozen;
   const messages = useMsgContext();
   const newMsgCtxt = useNewMsgContext();

   function isDisplayed() {
      if (isFrozen) return false;

      const index = messages.findIndex(msg => msg.id === props.message.id);

      const isLast = index === messages.length - 1;
      if (isLast) return true;

      const isOld = index + 7 < messages.length - 1;
      if (isOld) return false;

      const replyIndex = messages.findIndex((msg, i) => i > index && msg.party === SDKParticipantType.CUSTOMER);
      const isReplied = replyIndex === -1;
      return isReplied;
   }

   function onClick(option) {
      if (!option.payload) throw 'This option was not configured';

      newMsgCtxt.sendMessage(option.payload);
   }

   function getOptions() {
      return isDisplayed()
         ?  <div className='message-buttons'>
               { props.message.body.options.map(option => <button key={Math.random()} onClick={() => onClick(option)} disabled={isBusy}>{option.text}</button>) }
            </div>
         : null;
   }

   return (
      <MessageOptionsWrapper>
         <div className={ `message-body message-body-options message-body-${props.message.party}` }>
            <LinkifiedText text={ props.message.body.text } />
            { getOptions() }
         </div>
      </MessageOptionsWrapper>
   )
}

MessageOptions.propTypes = {
   message: PropTypes.object,
};

MessageOptions.defaultProps = {
   message: null,
};

export default MessageOptions;
