import styled from 'styled-components';

export const NotificationWrapper = styled.div`
    position: absolute;
    left: 9px;
    bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    width: calc(100% - 20px);
    padding: 20px 10px 10px 10px;
    border-radius: 6px;
    background: #e6e6e6;
    text-align: center;
    font-family: var(--font_family);
    font-size: var(--font_size_normal);

    .btn-close {
        background: white;
        padding: 5px;
        border-radius: 3px;
        cursor: pointer;
    }

    .btn-close:hover {
        background: var(--avaya_red);
        color: white;
    }
`;
