import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const rootEl = document.createElement('div');
rootEl.id = 'cct-chat-widget';
document.body.append(rootEl);
const root = ReactDOM.createRoot(document.getElementById('cct-chat-widget'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
