import React from 'react';
import { DialogWrapper } from './dialog.styled';
import { useProcessingContext } from '../../contexts/processing';
import { useMsgContext } from '../../contexts/msg';
import Message from '../message/message';

function Dialog() {

   const isBusy = useProcessingContext().isBusy;
   const scrollRef = React.createRef();

   const messages = useMsgContext();

   const isScrolled = (el) => {
      const scolled = el.scrollTop,
            frame = el.clientHeight,
            position = scolled + frame;

      return position === el.scrollHeight;
   }

   const scrollToLastMessage = React.useCallback(() => {
      const el = scrollRef.current;
      if (isScrolled(el)) return;

      setTimeout(() => {
         el.scrollTo({
            top: el.scrollHeight,
            left: 0,
            behavior: "smooth",
         });
      }, 0);
   }, [scrollRef]);

   React.useEffect(() => {
      if (!isBusy) scrollToLastMessage();
   }, [isBusy, scrollToLastMessage]);

   return (
      <DialogWrapper ref={scrollRef}>
         <section className={`${isBusy ? 'disabled' : ''}`}>
            { messages.map(message => <Message key={Math.random()} message={message} />) }
         </section>
      </DialogWrapper>
   )
}

export default Dialog;
