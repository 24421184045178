import React from 'react';
import { ProviderParams } from '../models/react';
import { UserContextType } from '../models/user';
import mockLocalstorage from '../mocks/localstorage.json';

const USER_INFO_STORAGE = 'ringUser';

const UserContext = React.createContext<UserContextType | undefined>(undefined);
export function useUserContext() {
    return React.useContext(UserContext);
}

export default function UserProvider({ children }: ProviderParams) {
    const [userInfo] = React.useState(getStorage());

    return (
        <UserContext.Provider value={ userInfo }>
            { children }
        </UserContext.Provider>
    );
}

function getStorage() {
    const   cookie = localStorage.getItem(USER_INFO_STORAGE),
            // ToDo: remove fallback
            ringUser = cookie ? JSON.parse(cookie) : undefined || mockLocalstorage;

    return ringUser?.value;
}