import config from "../config/config";
import { SDKInitConfig, SDKInitParams } from "../models/sdk";

export function getConfig(): SDKInitConfig {
    return {
        reconnectionTimeout: 120,
        idleTimeout: 900,
        idleShutdownGraceTimeout: 120,
        logLevel: 'DEBUG'
    };
}

export function getParams(jwt: string, integrationId: string): SDKInitParams {
    return {
        hostURL: config().url.axp.base,
        integrationId: integrationId,
        token: jwt,
        sessionParameters: {
            'model' : 'ABC-123'
        }
    };
}
