import React from 'react';
import { PopUpWrapper } from './pop-up.styled';
import Header from '../header/header';
import Status from '../status/status';
import Dialog from '../dialog/dialog';
import Actions from '../actions/actions';
import Notification from '../notification/notification';

function PopUp() {

   return (
      <PopUpWrapper>
         <Header />
         <Status />
         <Dialog />
         <Actions />
         <Notification />
      </PopUpWrapper>
   )
}

export default PopUp;
