import { Environment, EnvironmentConfig } from "../models/environment";
import envDev from "../environments/environment.dev";
import envProd from "../environments/environment.prod";
import envStage from "../environments/environment.stage";
import { envEndpoint } from "../environments/shared";

export default function config(): EnvironmentConfig {
    const env: Environment = getEnvironment();
    
    const AXP_BASE_URL = getUrl(env, 'axp');
    const API_BASE_URL = getUrl(env, 'api');
    const STATIC_BASE_URL = getUrl(env, 'static');
    return {
        env: env.env,
        bot: {
            name: env.bot.name
        },
        url: {
            axp: {
                base: AXP_BASE_URL
            },
            api: {
                base: API_BASE_URL,
                auth: API_BASE_URL + envEndpoint.api.backend,
                firestore: API_BASE_URL + envEndpoint.api.firestore
            },
            static: {
                base: STATIC_BASE_URL,
                assets: STATIC_BASE_URL + envEndpoint.static.media
            }
        },
        default_queue:env.default_queue,
        mandatory_properties: env.mandatory_properties,
        getMediaUrl: function (name: string) {
            return this.url.static.assets + '/' + name.split('/').pop();
        }
    };
}

function getUrl(env: Environment, type: string) {
    const api = env.url[type];
    const protocol = purifyProtocol(api.protocol || env.protocol);
    const location = protocol + api.host;
    const entrypoint = api.entrypoint;

    return [location, entrypoint].filter(Boolean).join('/');
}

function purifyProtocol(protocol: string) {
    const onFile = (protocol + '//').replace(/^file:\/\//, '.');
    return onFile;
}

function getEnvironment() {
    switch (process.env.REACT_APP_ENVIRONMENT) {
        case 'PROD': return envProd();
        case 'STAGE': return envStage();
    
        default: return envDev();
    }
}