import React from 'react';
import { NotificationTextWrapper } from './text.styled';

const secRegex = /\d+ seconds/;

let interval;
let expiresAt;

function NotificationText(props) {

   const [countdown, setCountdown] = React.useState(props.countdown);

   // UPDATES

   React.useEffect(() => {
      if (props.countdown) startCountdown();

      return () => {
         console.debug('Notification countdown CLEARED');
      }
   }, [props.countdown]);

   React.useEffect(() => {
      if (props.countdown) updateCountdown();

      return () => {
         clearCountdown();
      }
   }, [countdown]);

   // ACTIONS

   function startCountdown() {
      console.debug('Notification countdown STARTED');
      expiresAt = new Date().getTime() + props.countdown * 1000;
      setCountdown(props.countdown);
   }

   function updateCountdown() {
      interval = setInterval(() => {
         const left = Math.round( (expiresAt - new Date().getTime()) / 1000 );
         setCountdown(left);
         props.onCountdown(left);
         console.debug(`Notification will be closed in ${left} seconds`);
      }, 1000);
   }

   function clearCountdown() {
      clearInterval(interval);
   }

   // RENDER

   return (
      <NotificationTextWrapper>
         <div className='message'>{ props.text.replace(secRegex, countdown + ' seconds') }</div>
      </NotificationTextWrapper>
   )
};

export default NotificationText;
