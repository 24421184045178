import React from 'react';
import { NotificationWrapper } from './notification.styled';
import { useProcessingContext } from '../../contexts/processing';
import NotificationText from './text/text';

let timer;
let secondsLeft;
let closedReason;
let newNotification;

function Notification() {

   const processingCtxt = useProcessingContext();

   const notification = processingCtxt?.notification;
   const message = notification.message;
   const close = notification.close;
   const isNoClose = close?.isNoClose;
   const auto = close?.auto;
   const onManual = close?.onManual;
   const onAuto = close?.onAuto;
   const onInterrupt = close?.onInterrupt;

   newNotification = notification;

   // ACTIONS

   const closeNotification = React.useCallback((text, reason, callback) => {
      console.debug(text);
      closedReason = reason;
      processingCtxt?.setNotification({message: ''});
      callback && callback(secondsLeft);
      secondsLeft = null;
   }, [processingCtxt]);

   const closeAuto = React.useCallback(() => {
      closeNotification('Notification was closed automatically', 'AUTO', onAuto);
   }, [closeNotification, onAuto]);

   const closeManual = React.useCallback(() => {
      closeNotification('Notification was closed manually', 'MANUAL', onManual);
   }, [closeNotification, onManual]);

   const closeInterrupted = React.useCallback(() => {
      console.debug('Notification was closed on interruption');
      onInterrupt && onInterrupt(secondsLeft);
   }, [onInterrupt]);

   const closeAborted = React.useCallback(() => {
      console.debug('Notification was aborted');
   }, []);

   // UPDATES

   React.useEffect(() => {
      closedReason = 'NONE';

      return () => {
         closedReason = 'NONE';
      }
   }, []);

   React.useEffect(() => {
      if (auto) startTimer();

      return () => {
         if (auto) clearTimer();
      }
   }, [message, auto]);

   // ACTIONS

   function startTimer() {
      console.debug('Notification timer STARTED');
      timer = setTimeout(closeAuto, auto * 1000);
   }

   function clearTimer() {
      if (!timer) return;

      console.debug('Notification timer CLEARED');

      clearTimeout(timer);
      timer = null;

      handleChange();
   }

   function handleChange() {
      const isOverwritten = 'NONE' === closedReason,
            isNotExpired = secondsLeft > 1,
            isAborted = newNotification.message === '';

      if (isOverwritten && isNotExpired) isAborted ? closeAborted() : closeInterrupted();
   }

   function onCountdown(seconds) {
      secondsLeft = seconds;
      if (0 === seconds || seconds < 0) closeAuto();
   }

   // RENDER

   if (!message) return null;

   return (
      <NotificationWrapper>
         <NotificationText text={message} countdown={auto} onCountdown={onCountdown}/>
         {isNoClose ? null : <div className='btn-close' onClick={ closeManual }>close</div>}
      </NotificationWrapper>
   )
};

export default Notification;
