import { useEffect, useState } from 'react';

function getQueryParam(paramName: string): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(paramName);
}

function useQueryParameter(paramName: string): string | null {
  const [paramValue, setParamValue] = useState<string | null>(getQueryParam(paramName));

  useEffect(() => {
    const updateQueryParamChange = () => setParamValue(getQueryParam(paramName));
    const unlisten = () => window.removeEventListener('click', updateQueryParamChange);

    window.addEventListener('click', updateQueryParamChange);

    return unlisten;
  }, [paramName]);

  return paramValue;
}

export default useQueryParameter;