import { StateSetter } from './react';
import { SDKMessage, SDKMessageBody, SDKMessageType, SDKParticipantType, SDKUserContext } from './sdk';

// ACTIONS

export interface MSGSendNewMessage {
  (): Promise<void>;
}

export interface MSGSendMessage {
  (message: string | MSGItemBody): Promise<void>;
}

export interface MSGAddMessage {
  (message: string, participant: SDKParticipantType | MSGPartyType, isTemp?: boolean, name?: string): void;
}
export interface MSGRemoveTempMessages {
  ():void
}
export interface MSGAddRichMessage {
  (message: SDKMessage): void;
}

export interface MSGClearDialog {
  (isAll?: boolean): void;
}

export interface MSGUpdateParticipant {
  (userContext: SDKUserContext, isAdd: boolean): void;
}

// CONTENT

export enum MSGType {
  text = 'text',
  options = 'options',
  unknown = 'unknown',
}

export enum MSGActionType {
  reply = 'reply',
}

export type MSGParsed = MSGParsedText | MSGParsedOptions | MSGParsedUnknown;

export interface MSGParsedText {
  body: MSGBodyParsed<SDKMessageType.plaintext>;
}

export interface MSGParsedOptions {
  body: MSGBodyOptions;
}

export interface MSGParsedUnknown {
  body: MSGBodyParsed<SDKMessageType.unknown>;
}

export interface MSGBodyOptions extends MSGBodyParsed<SDKMessageType.buttons> {
  richMediaPayload: {
    actions: Array<MSGAction>;
  }
}

export interface MSGBodyParsed<T> extends SDKMessageBody<T> {
}

export interface MSGAction {
  type: MSGActionType;
  text: string;
  payload: string;
  iconUrl: string;
}

// LISt

export enum MSGItemType {
  outgoing = 'outgoing',
  incoming = 'incoming',
}

export enum MSGPartyType {
  UI = 'UI',
}

export type MSGItem = MSGItemText | MSGItemOptions | MSGItemUnknown;

export interface MSGItemText extends MSGItemGeneric {
  type: MSGType.text;
  body: string;
}

export interface MSGItemOptions extends MSGItemGeneric {
  type: MSGType.options;
  body: MSGItemBody;
}

export interface MSGItemUnknown extends MSGItemGeneric {
  type: MSGType.unknown;
  body: string;
}

export interface MSGItemGeneric {
  id: string;
  direction: MSGItemType;
  party: SDKParticipantType | MSGPartyType;
  displayName: string;
  isTemp: boolean;
}

export interface MSGItemBody {
  text: string;
  options: Array<MSGAction>;
}

// CONTEXT

export type MSGContextType = Array<MSGItem>;

export interface MSGNewContextType {
  newMessage: string;
  setNewMessage: StateSetter<string>;
  sendNewMessage: MSGSendNewMessage;
  sendMessage: MSGSendMessage;
  removeTempMessage:MSGRemoveTempMessages;
  addMessage: MSGAddMessage;
  addRichMessage: MSGAddRichMessage;
  clearDialog: MSGClearDialog;
  updateParticipant: MSGUpdateParticipant;
}
