import React from 'react';
import { StatusWrapper } from './status.styled';
import { useStatusContext } from '../../contexts/status';

function Status() {
   const statusCtxt = useStatusContext();
   const status = statusCtxt.isOnline ? 'online' : 'offline';

   return (
      <StatusWrapper>
         <div className={'status status-' + status}></div>
         <div className='title'>{statusCtxt.title}</div>
      </StatusWrapper>
   )
};

export default Status;
