import './App.css';
import React from 'react';
import config from "./config/config";

import ProcessingProvider from './contexts/processing';
import UserProvider from './contexts/user';
import ToggleProvider from './contexts/toggle';
import Widget from './components/widget/widget';
import useQueryParameter from './hooks/query-param.hook';

const MANDATORY_PARAMETERS = config().mandatory_properties 

function App() {
  if (!isDisplayed()) return null;
  return (
    <ProcessingProvider>
      <UserProvider>
        <ToggleProvider>
          <Widget />
        </ToggleProvider>
      </UserProvider>
    </ProcessingProvider>
  );
}

function isDisplayed() {
  const parameterList = Object.keys(MANDATORY_PARAMETERS)

  const filteredlist = parameterList.filter(parameter =>{
    let URLParameter = useQueryParameter(parameter)
    return (MANDATORY_PARAMETERS[parameter].includes(URLParameter))
  })
  return (parameterList.length === filteredlist.length) 
}

export default App;
