import React from 'react';
import StatusProvider from '../../contexts/status';
import EngagementProvider from '../../contexts/engagement';
import MsgProvider from '../../contexts/msg';
import { WidgetWrapper } from './widget.styled';
import { useToggleContext } from '../../contexts/toggle';
import PopUp from '../pop-up/pop-up'
import Toggle from '../toggle/toggle'

function Widget() {
   const toggle = useToggleContext();

   return (
      <StatusProvider>
         <EngagementProvider>
            <MsgProvider>
               <WidgetWrapper>
                  <div className={`cct-chat-widget ${toggle?.isOpen ? 'cct-chat-widget-opened' : ''}`}>
                     {toggle?.isOpen ? <PopUp /> : null}
                     <Toggle />
                  </div>
               </WidgetWrapper>
            </MsgProvider>
         </EngagementProvider>
      </StatusProvider>
   );
}

export default Widget;
