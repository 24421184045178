import React from 'react';
import { MessageUnknownWrapper } from './message-unknown.styled';
import LinkifiedText from '../content/linkified-text';

const UNKNOWN = 'Unknown content';

function MessageUnknown() {
   return (
      <MessageUnknownWrapper>
         <div className='message-body message-body-unknown'>
            <LinkifiedText text={ UNKNOWN } />
         </div>
      </MessageUnknownWrapper>
   )
}

export default MessageUnknown;
