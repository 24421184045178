import React from 'react';
import ActionsMenuProvider from '../../contexts/actions-menu';
import { ActionsWrapper } from './actions.styled';
import { useProcessingContext } from '../../contexts/processing';
import { useNewMsgContext } from '../../contexts/msg';
import ActionMenu from './actions-menu';

function Actions() {

   const ref = React.useRef();
   const isBusy = useProcessingContext().isBusy;
   const isFrozen = useProcessingContext().isFrozen;
   const newMsgCtxt = useNewMsgContext();

   React.useEffect(()=> {
      if (!isBusy) ref.current.focus();
   }, [isBusy, ref])

   async function sendNewMessage(event) {
      event?.preventDefault();
      await newMsgCtxt.sendNewMessage();
      setTimeout(() => {
         ref.current.focus();         
      }, 0);
   };

   return (
      <ActionsMenuProvider>
         <ActionsWrapper>
            <div className='send-message'>
               <ActionMenu/>
               <form onSubmit={sendNewMessage}>
                  <input
                     ref={ref}
                     id='new-message'
                     className=''
                     aria-label='Message to send'
                     placeholder='Write a reply'
                     autoFocus={true}
                     disabled={isFrozen || isBusy || false}
                     value={newMsgCtxt.newMessage}
                     onChange={e => newMsgCtxt.setNewMessage(e.target.value)} />
                  <button
                     className=''
                     aria-label='Send Message'
                     type='button'
                     disabled={!newMsgCtxt.newMessage || isFrozen || isBusy || false}
                     onClick={sendNewMessage}>
                     <svg width='24' height='24' viewBox='0 0 24 24'>
                        <path d='M2.01 21L23 12 2.01 3 2 10l15 2-15 2z'></path>
                     </svg>
                  </button>
               </form>
            </div>
         </ActionsWrapper>
      </ActionsMenuProvider>
   )
}

export default Actions;
