import { UserData } from "../models/user";

// SDK

export interface SDK {
  createEngagement: (data: UserData) => SDKEngagement;
  getEngagementById: (id: string) => any;
  getEngagements: Function;
  init: (params: SDKInitParams, config: SDKInitConfig) => Promise<void>;
  off: Function; // one unknown parameter
  on: (name: string, callback: Function) => void;
  resetIdleTimeout: Function;
  retryConnection: Function;
  setJwtToken: (jwt: string) => void;
  setLogLevel: Function; // one unknown parameter
  shutdown: Function;
  version: Function;
}

export interface SDKInitConfig {
  reconnectionTimeout: number;
  idleTimeout: number;
  idleShutdownGraceTimeout: number;
  logLevel: string;
}

export interface SDKInitParams {
  hostURL: string;
  integrationId: string;
  token: string;
  displayName?: string;
  sessionParameters?: {
    model: string;
  }
}

// ENGAGEMENT

export enum SDKEngagementStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
}

export interface SDKEngagement {
  createdAt: Date;
  dialogId: string;
  disconnect: () => Promise<void>;
  engagementId: string;
  engagementStatus: SDKEngagementStatus;
  fetchEngagementStatus: Function;
  fetchLastUpdateTime: Function;
  fetchParticipantList: Function;
  getMessages: Function;
  getParticipantById: Function;
  join: Function;
  lastUpdatedAt: Date;
  participants: Array<SDKParticipant>;
  sendMessage: (message: any) => Promise<{ messageId: number }>;
}

// EVENT

export enum SDKParticipantType {
  SYSTEM = 'SYSTEM',
  CUSTOMER = 'CUSTOMER',
  AGENT = 'AGENT',
}

export interface SDKParticipant {
  participantId: string;
  participantType: SDKParticipantType;
  displayName: string;
}

export interface SDKUserContext {
  participant: SDKParticipant;
}

export interface SDKEventPayload {
  gracePeriod: number;
}

// MESSAGE

export enum SDKMessageType {
  plaintext = 'plaintext',
  buttons = 'buttons',
  unknown = 'unknown',
}

export enum SDKMessageTextFormat {
  PLAINTEXT = 'PLAINTEXT',
}

export interface SDKMessageBody<T> {
  elementType: T;
  elementText: {
    text: string;                         // stringified JSON (format: `${MSGParsed}<end>/n${MSGParsed}>end>`)
    textFormat: SDKMessageTextFormat;
  };
  richMediaPayload?: {}
}

export interface SDKMessage {
  senderParticipant: SDKParticipant;
  engagementId:string;
  body: SDKMessageBody<SDKMessageType>;
}

// UI

export interface SDKStage {
  state: SDKStageType;
  setState: (state: SDKStageType) => void;
  ongoing: SDKStageType | null;
  setExpectation: (state: SDKStageType) => void;
  clearExpectation: () => void;
}

export enum SDKStageType {
  IDLE = 'IDLE',

  AUTH_STARTED = 'AUTH_STARTED',
  AUTH_DENIED = 'AUTH_DENIED',
  AUTH_FINISHED = 'AUTH_FINISHED',
  AUTH_INTERRUPTED = 'AUTH_INTERRUPTED',

  INIT_PENDING = 'INIT_PENDING',
  INIT_STARTED = 'INIT_STARTED',
  INIT_ENGAGEMENT = 'INIT_ENGAGEMENT',
  INIT_ENGAGEMENT_CREATED = 'INIT_ENGAGEMENT_CREATED',
  INIT_REQUESTED = 'INIT_REQUESTED',
  INIT_EXECUTED = 'INIT_EXECUTED',
  INIT_FINISHED = 'INIT_FINISHED',
  INIT_FAILED = 'INIT_FAILED',

  SHUT_PENDING = 'SHUT_PENDING',
  SHUT_STARTED = 'SHUT_STARTED',
  SHUT_ENGAGEMENT = 'SHUT_ENGAGEMENT',
  SHUT_ENGAGEMENT_DISSCONNECTED = 'SHUT_ENGAGEMENT_DISSCONNECTED',
  SHUT_REQUESTED = 'SHUT_REQUESTED',
  SHUT_EXECUTED = 'SHUT_EXECUTED',
  SHUT_FINISHED = 'SHUT_FINISHED',
  SHUT_FAILED = 'SHUT_FAILED',
  SHUT_EXPECTED = 'SHUT_EXPECTED',
}
