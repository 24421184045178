import React from 'react';
import { ProviderParams } from '../models/react';
import { ToggleContextType } from '../models/toggle';

const ToggleContext = React.createContext<ToggleContextType | undefined>(undefined);
export function useToggleContext() {
    return React.useContext(ToggleContext);
}

export default function ToggleProvider({ children }: ProviderParams) {
    const [isOpen, setIsOpen] = React.useState(false);
  
    const onClick = () => {
      setIsOpen(state => !state);
    }

    return (
        <ToggleContext.Provider value={ { isOpen, onClick } }>
            { children }
        </ToggleContext.Provider>
    );
}
