import styled from 'styled-components';

export const NotificationTextWrapper = styled.div`
    .message {
        padding: 0 20px;
    }

    .message:last-child {
        margin-bottom: 10px;
    }
`;
