import React from 'react';
import { useProcessingContext } from './processing';
import { useStatusContext } from './status';
import { useEngagementContext } from './engagement';
import { ProviderParams } from '../models/react';
import { SDKMessage, SDKMessageType, SDKParticipantType, SDKUserContext } from '../models/sdk';
import { MSGType, MSGParsed, MSGItem, MSGItemType, MSGItemBody, MSGNewContextType, MSGContextType, MSGPartyType } from '../models/msg';

const MsgContext = React.createContext<MSGContextType | undefined>(undefined);
export function useMsgContext() {
    return React.useContext(MsgContext);
}

const NewMsgContext = React.createContext<MSGNewContextType | undefined>(undefined);
export function useNewMsgContext() {
    return React.useContext(NewMsgContext);
}

export default function MsgProvider({ children }: ProviderParams) {
    const processingCtxt = useProcessingContext();
    if (!processingCtxt) throw new Error('Missing processing context');

    const statusCtxt = useStatusContext();
    if (!statusCtxt) throw new Error('Missing header context');

    const engagementCtxt = useEngagementContext();
    if (!engagementCtxt) throw new Error('Missing engagement context');

    const setIsBusy = processingCtxt.setIsBusy;

    const [newMessage, setNewMessage] = React.useState('');
    const [messages, setMessages] = React.useState<Array<MSGItem>>([]);
    // ToDo: remove mock data

    // MESSAGES

    async function sendNewMessage(): Promise<void> {
        if (!newMessage) return;

        return sendMessage(newMessage);
    };

    async function sendMessage(message: string | MSGItemBody): Promise<void> {
        if (!engagementCtxt?.engagement) {
            console.warn(`User attempts to send a message but no engagement is found:\n  User's message: '${message}'`);
            addMessage('Current session was terminated.', MSGPartyType.UI);
            return;
        };

        addMessages([formatMessage('sending...', MSGPartyType.UI, true)]);
        setNewMessage('');

        setIsBusy(true);
        const response = await engagementCtxt.engagement.sendMessage(message);
        setIsBusy(false);
        if (!response) throw new Error('Failed to send message');

        console.debug(response.messageId);

        return;
    };

    function addMessages(message: Array<MSGItem>): void {
        setMessages(prevState => {
            const   prev = prevState.map(el => el),
                    last = prev.pop(),
                    isTemp = last?.isTemp,
                    newState = isTemp ? prev : prevState;

            return [...newState, ...message];
        });
    }

    function removeTempMessage(){
        setMessages(prevState => {
            const   prev = prevState.map(el => el),
                    last = prev.pop(),
                    isTemp = last?.isTemp,
                    newState = isTemp ? prev : prevState;

            return [...newState];
        });
    }

    function addMessage(message: string, participant: SDKParticipantType | MSGPartyType, isTemp = false, name = ''): void {
        const msg = formatMessage(message, participant, isTemp, name);
        addMessages([msg]);
    }

    function addRichMessage(message: SDKMessage): void {
        const   sender = message.senderParticipant,
                content = message.body.elementText.text.trim().split(/<end>\n|<end>/).filter(msg => msg),
                messages: Array<MSGItem> = [];

        content.forEach((str: string) => {
            const   parsed: MSGParsed = JSON.parse(str),
                    type = getType(parsed.body.elementType),
                    body = getBody(parsed),
                    message = formatMessage(body, sender.participantType, false, sender.displayName, type);
    
            console.log(sender.participantType + ' says:', body, '\n message:', message, '\n parsed:', parsed);
            messages.push(message);
        });

        addMessages(messages);
    }

    function clearDialog(isAll = false): void {
        if (isAll) setMessages(prevState => {
            const last = prevState.filter(msg => MSGPartyType.UI === msg.party).pop();
            return last ? [last] : [];
        });
        else setMessages([]);
    }

    // PARTICIPANTS

    function updateParticipant(message: SDKUserContext, isAdd: boolean): void {
        const   name = message.participant.displayName,
                text = isAdd ? `${name} has joined.` : `${name} has left.`,
                msg = formatMessage(text, MSGPartyType.UI);

        addMessages([msg]);
        statusCtxt?.setAgent(isAdd ? name : undefined);
    }

    return (
        <MsgContext.Provider value={ messages }>
            <NewMsgContext.Provider value={ { newMessage, setNewMessage, sendNewMessage, sendMessage, addMessage, removeTempMessage,  addRichMessage, clearDialog, updateParticipant } }>
                { children }
            </NewMsgContext.Provider>
        </MsgContext.Provider>
    );
}

function formatMessage(body: string | MSGItemBody, participant: SDKParticipantType | MSGPartyType, isTemp = false, name = '', type = MSGType.text): MSGItem {
    return {
        id: Math.random().toString(36).substring(2, 15),
        direction: 'CUSTOMER' === participant ? MSGItemType.outgoing : MSGItemType.incoming,
        party: participant,
        displayName: name || '',
        isTemp: isTemp,
        type: type,
        body: body
    } as MSGItem;
}

function getType(type: SDKMessageType): MSGType {
    switch (type) {
        case 'plaintext': return MSGType.text;
        case 'buttons': return MSGType.options;
        default: return MSGType.unknown;
    }
}

function getBody(content: MSGParsed): string | MSGItemBody {
    switch (content.body.elementType) {
        case 'plaintext': return content.body.elementText.text; 
        case 'buttons': return {
            text: content.body.elementText.text,
            options: content.body.richMediaPayload?.actions || []
        };   
        default: return 'unknown';
    }
}
