import React from 'react';
import { ProviderParams } from '../models/react';
import { EngagementContextType } from '../models/engagement';
import { SDKEngagement } from '../models/sdk';

const EngagementContext = React.createContext<EngagementContextType | undefined>(undefined);
export function useEngagementContext() {
    return React.useContext(EngagementContext);
}

export default function EngagementProvider({ children }: ProviderParams) {
    const [engagement, setEngagement] = React.useState<SDKEngagement | undefined>();

    return (
        <EngagementContext.Provider value={ {engagement, setEngagement} }>
            { children }
        </EngagementContext.Provider>
    );
}