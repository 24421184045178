import styled from 'styled-components';

export const ActionsWrapper = styled.div`

    background: white;
    border-top: 1px solid #cdcdcd;

    .send-message,
    form {
        display: flex;
        gap: 16px;
    }

    .send-message button {
        background: transparent;
        transition: none;
    }

    .send-message button:not([disabled]) {
        cursor: pointer;
        fill: rgba(0, 0, 0, 0.54);
    }

    .send-message button:not([disabled]):hover {
        fill: rgb(218, 41, 28);
    }

    .send-message button[disabled] {
        pointer-events: none;
        cursor: default;
        fill: rgba(0, 0, 0, 0.2);
    }

    .send-message form,
    .send-message form input {
        flex: 1;
    }

    .send-message form input {
        width: 100%;
        font-family: var(--font_family);
        font-size: calc(var(--font_size_normal) * 1.15);
        line-height: 1.15;
        letter-spacing: unset;
        text-overflow: ellipsis;
    }

    .send-message form input:first-child {
        margin-left: 8px;
    }

    .send-message form input,
    .send-message form input:focus {
        border: none;
        outline: none;
    }

    .send-message form input:disabled {
        opacity: .5;
    }
`;
