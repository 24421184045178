import React from 'react';
import { HeaderWrapper } from './header.styled';
import config from '../../config/config';
import logo from '../../assets/img/NewAvayaLogo.png';

function Header() {
   return (
      <HeaderWrapper>
         <header>
            <div className='logo'>
               <img width='140' src={config().getMediaUrl(logo)} alt='AVAYA logo'></img>
            </div>
         </header>
      </HeaderWrapper>
   )
};

export default Header;
