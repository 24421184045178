import React from 'react';
import { ProviderParams } from '../models/react';
import { StatusContextType } from '../models/status';
import config from '../config/config';

const BOT_NAME = config().bot.name;

const StatusContext = React.createContext<StatusContextType | undefined>(undefined);
export function useStatusContext() {
    return React.useContext(StatusContext);
}

export default function StatusProvider({ children }: ProviderParams) {
    const [title, setTitle] = React.useState<string>(BOT_NAME);
    const [isOnline, setIsOnline] = React.useState<boolean>(false);

    function setAgent(name?: string) {
        setTitle(name ? name : BOT_NAME);
    }

    return (
        <StatusContext.Provider value={ {title, setTitle, isOnline, setIsOnline, setAgent} }>
            { children }
        </StatusContext.Provider>
    );
}