import React from 'react';
import { ProviderParams } from '../models/react';
import { ActionsMenuContextType } from '../models/actions-menu';

const ActionsMenuContext = React.createContext<ActionsMenuContextType | undefined>(undefined);
export function useActionsMenuContext() {
    return React.useContext(ActionsMenuContext);
}

export default function ActionsMenuProvider({ children }: ProviderParams) {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <ActionsMenuContext.Provider value={ { isOpen, setIsOpen } }>
            { children }
        </ActionsMenuContext.Provider>
    );
}
