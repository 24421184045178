import React from 'react';
import PropTypes from 'prop-types';
import { MessageTextWrapper } from './message-text.styled';
import LinkifiedText from '../content/linkified-text';

function MessageText(props) {
   return (
      <MessageTextWrapper>
         <div className={ `message-body message-body-text message-body-${props.message.party}` }>
            <LinkifiedText text={ props.message.body } />
         </div>
      </MessageTextWrapper>
   )
}

MessageText.propTypes = {
   message: PropTypes.object,
};

MessageText.defaultProps = {
   message: null,
};

export default MessageText;
