import styled from 'styled-components';

export const ActionMenuWrapper = styled.div`

    .menu-pop-up {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 1px;
        background-color: #e6e6e6;
        border-top: 1px solid #cdcdcd;
        z-index: 1;
    }

    .menu-pop-up > button {
        padding: 11px 8px;
        width: 100%;
        background-color: white;
        font-size: 20px;
        transition: .5s;
    }

    .menu-pop-up > button:hover {
        background: var(--avaya_teal);
        color: white;
    }
`;
