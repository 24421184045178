import styled from 'styled-components';

export const HeaderWrapper = styled.div`

    background: white;
    border-bottom: 1px solid #cdcdcd;

    header {
        display: flex;
        flex-basis: 90px;
        align-items: flex-start;
        gap: 10px;
        box-sizing: border-box;
    }

    header .logo {
        flex: 1;
        display: flex;
        gap: 5px;
        padding: 7px 8px 0px 8px;
    }
`;
