import React from 'react';
import PropTypes from 'prop-types';
import { MessageWrapper } from './message.styled';
import { AppAvatar } from './content/avatar-app';
import { BotAvatar } from './content/avatar-bot';
import { AgentAvatar } from './content/avatar-agent';
import { UserAvatar } from './content/avatar-user';
import MessageText from './types/message-text';
import MessageOptions from './types/message-options';
import MessageUnknown from './types/message-unknown';
import { SDKParticipantType } from '../../models/sdk';
import { MSGPartyType } from '../../models/msg';
import '../../assets/styles/neo-icons.css';

function Message(props) {

   function getAvatar() {
      switch (props.message.party) {
         case MSGPartyType.UI:
            return <AppAvatar/>;

         case SDKParticipantType.SYSTEM:
            return <BotAvatar />;

         case SDKParticipantType.AGENT:
            return <AgentAvatar />;

         case SDKParticipantType.CUSTOMER:
            return <UserAvatar />;
      
         default: return null;
      }
   };

   function getContent() {
      const message = props.message;

      switch (message.type) {
         case 'text':
            return <MessageText message={message} />;
         case 'options':
            return <MessageOptions message={message} />;
      
         default:
            console.warn(`Unknown content type: ${ message.type }`);
            return <MessageUnknown />;
      }
   };

   return (
      <MessageWrapper>
         <div key={Math.random()} className={`message-block message-${props.message.direction} message-${props.message.party}`}>
            <div className='message-avatar'>{ getAvatar() }</div>
            <div className='message-content'>{ getContent() }</div>
         </div>
      </MessageWrapper>
   )
}

Message.propTypes = {
   message: PropTypes.object,
};

Message.defaultProps = {
   message: null,
};

export default Message;
