import React from 'react';
import PropTypes from 'prop-types';
import Linkify from 'linkify-react';

const MAX_CHARS = 35;

/**
 * Displays text with clickable links and labels utilizing Linkify
 * (links without label can be used without markup).
 * @example 'Click on [[https://url.com | this link]].'
 * */
function LinkifiedText(props) {

   // MARKUP

   const cleanedText = props.text.replaceAll(/\[\[\s{0,1}([^\]|\s]+)(\s\|\s){0,1}([^\]]+)\s{0,1}\]\]/g, '$1');

   const getLink = React.useCallback((markup) => {
      const meta = markup.replace(/\[\[\s{0,1}|\s{0,1}\]\]/g,'').split(/\s\|\s/);
      return { url: meta[0]?.trim(), label: meta[1]?.trim() };
   }, []);

   const markedup = props.text.match(/\[\[[^\]]+\]\]/g)?.map(markup => getLink(markup));

   // OPTIONS

   const getLabel = React.useCallback((value) => markedup?.find(link => value === link.url)?.label, [markedup]);
   const getTruncated = React.useCallback((value) => value.length > MAX_CHARS ? value.slice(0, MAX_CHARS) + '…' : value, []);

   const options = {
      format: {
         url: (value) => getLabel(value) || getTruncated(value),
      },
   };

   // RENDER

   return (
      <Linkify as='div' options={options} className='message-text'>{ cleanedText }</Linkify>
   )
}

LinkifiedText.propTypes = {
   text: PropTypes.string,
};

LinkifiedText.defaultProps = {
   text: null,
};

export default LinkifiedText;
