import React from 'react';
import { useActionsMenuContext } from '../../../contexts/actions-menu';
import { useNewMsgContext } from '../../../contexts/msg';
import { useMsgContext } from '../../../contexts/msg';
import { MSGItem, MSGItemBody, MSGPartyType } from '../../../models/msg';
import { SDKParticipantType } from '../../../models/sdk';
import config from '../../../config/config';

const BOT_NAME = config().bot.name;

interface Timestamp {
   date: Array<string>,
   time: Array<string>
}

function DownloadTrascript() {
   const menuCtxt = useActionsMenuContext();
   const newMsgCtxt = useNewMsgContext();
   const messages = useMsgContext();

   function download() {
      downloadTranscript();
      newMsgCtxt?.addMessage('Downloaded transcript.', MSGPartyType.UI);
      menuCtxt?.setIsOpen(false);
   }

   function downloadTranscript() {
      const link = document.createElement('a');

      const timestamp = getTimestamp();
      const text = compileText(timestamp);
      const file = compileFile(text, timestamp);

      link.href = URL.createObjectURL(file);
      link.download = getName(timestamp);
      link.click();

      URL.revokeObjectURL(link.href);
   }

   // TIMESTAMP

   function getTimestamp(): Timestamp {
      const date = new Date();

      const year = date.getFullYear().toString();
      const month = pad(date.getMonth() + 1);
      const day = pad(date.getDate());

      const hh = pad(date.getHours());
      const mm = pad(date.getMinutes());
      const ss = pad(date.getSeconds());

      return {
         date: [year, month, day],
         time: [hh, mm, ss]
      };
   }

   function pad(num: number) {
      return num.toString().padStart(2, '0');
   }

   // TEXT

   function compileText(timestamp: Timestamp) {
      const transcript = [];

      transcript.push('');
      transcript.push(window.location.href);
      transcript.push('\n------------------\n');
      messages?.forEach((msg) => {
         const line = formatMessage(msg);
         transcript.push(line);
      })
      transcript.push('\n------------------\n');
      transcript.push(timestamp.date.join('-') + ' ' + timestamp.time.join(':'));
      transcript.push('');

      return transcript.join('\n');
   }

   function formatMessage(msg: MSGItem) {
      const party = msg.displayName + getRole(msg);
      const text = getMsgText(msg.body).replaceAll('\n', '\n — ');

      return ' ' + party + ': \n — ' + text + '\n';
   }

   function getRole(msg: MSGItem) {
      const role = getRoleName(msg.party);
      return msg.displayName ? ' (' + role + ')' : role;
   }

   function getRoleName(party: SDKParticipantType | MSGPartyType) {
      switch (party) {
         case 'SYSTEM': return BOT_NAME;
         case 'CUSTOMER': return 'You';
         case 'AGENT': return 'Agent';
         default: return 'Notification';
      }
   }

   function getMsgText(body: string | MSGItemBody) {
      if (typeof body === 'string') return body;
      else return body.text;
   }

   // FILE

   function compileFile(text: string, timestamp: Timestamp) {
      const blob = new Blob([text], { type: 'text/plain' });
      const file = new File([blob], getName(timestamp), { type: 'text/plain' });

      return file;
   }

   function getName(timestamp: Timestamp) {
      const bot = BOT_NAME.replaceAll(' ', '_').replaceAll(/[^a-zA-Z0-9_-]/g, '');
      const date = timestamp.date.join('-') + '_' + timestamp.time.join('-');

      return bot + '_' + date + '.txt';
   }

   // RENDER

   return (
      <button title='Download transcript' type='button' aria-label='Download transcript' className='neo-icon-download' onClick={download}></button>
   );
};

export default DownloadTrascript;
