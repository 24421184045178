import styled from 'styled-components';

export const DialogWrapper = styled.div`
    flex: 1;
    height: 56px;
    overflow-y: auto;
    padding: 16px 10px;
    background-color: var(--avaya_grey_light);
    font-family: var(--font_family);
    font-size: var(--font_size_normal);

    section {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 36px;
    }
`;
