import { FirestoreUserData, AuthUserData } from "../models/user";

// REQUEST

export function getOptions_POST(data: AuthUserData | FirestoreUserData) {
    return {
		method: 'POST',
		headers: {
            'Content-Type': 'application/json'
		},
		timeout: 0,
        body: JSON.stringify(data),
	};
}

export async function parseResponse(type: string, response: any): Promise<any> { 
    if (!response) throw new Error(`${type} failed`);

    console.debug(`${type} received:`, response);

    try {
        const data = await response.json();
        console.debug(`${type} parsed:`, data);
        return data;
    } catch (error) {
        console.error(`${type} response is not a JSON`);
        throw getError(response, `${type} response is not a JSON, ${error}`);
    }
}

// ERROR

interface ResponseError extends Error {
    statusText?: string;
    status?: number;
}

function getError(response: Response | ResponseError, fallback: string) {
    const error: ResponseError = new Error();
    error.status = response.status;
    error.statusText = `${response.statusText || fallback}${error.status ? '' : ': ' + JSON.stringify(response)}`;

    return error;
}
