import styled from 'styled-components';

export const PopUpWrapper = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
    height: 100%;
    max-height: 100%;
    width: 380px;
    border-radius: 6px;
    box-shadow: var(--shadow_main);
    overflow: hidden;
    animation: grow 0.5s;

    @keyframes grow {
        0% {
          height: 0;
        }
        100% {
          height: 100%;
        }
    }

    > * {
        padding: 8px;
    }
`;
