import config from "../config/config";
import { MESSAGE_SYSTEM_FAILED, MESSAGE_UNAUTHORIZED_USER } from "../constants";
import { AuthResponse, AuthResponseParsedJwt, AuthResponseParsed, AuthResponseServer } from "../models/auth";
import { UserContextType, AuthUserData } from "../models/user";
import { getOptions_POST, parseResponse } from "./http";

function getBody(user: UserContextType): AuthUserData {
    return {
        userId: user.userId,
        customerName: user.firstName + ' ' + user.lastName,
        accessToken: user.accessToken,
        userEmail: user.emailId,
    };
}

function isAuthJwt(response: AuthResponseParsed): response is AuthResponseParsedJwt {
    return Object.keys(response).includes('jwt');
}

function getAuthMessage(response: AuthResponseServer, parsed: AuthResponseParsed): string {
    if (isAuthJwt(parsed)) return !parsed.jwt || !parsed.integrationId ? MESSAGE_UNAUTHORIZED_USER : '';

    if ([401].includes(response.status)) return MESSAGE_UNAUTHORIZED_USER;

    if ([404, 500, 501, 502, 503].includes(response.status)) return MESSAGE_SYSTEM_FAILED;

    return parsed.message;
}

export default async function getAuth(user: UserContextType): Promise<AuthResponse> {
    try {
        console.debug(`AUTH requested...`);

        const   response: AuthResponseServer = await fetch(config().url.api.auth, getOptions_POST( getBody(user) )),
                parsed: AuthResponseParsed = await parseResponse('AUTH', response),
                displayMessage = getAuthMessage(response, parsed);

        return {
            ...parsed,
            displayMessage
        }
    } catch (error: any) {
        console.error(`Failed to retrieve AUTH data`);
        throw error;
    }
}
