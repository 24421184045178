import React from 'react';
import { ProviderParams } from '../models/react';
import { ProcessingContextType } from '../models/processing';

const ProcessingContext = React.createContext<ProcessingContextType | undefined>(undefined);
export function useProcessingContext() {
    return React.useContext(ProcessingContext);
}

export default function ProcessingProvider({ children }: ProviderParams) {
    const [isBusy, setIsBusy] = React.useState(false);
    const [isFrozen, setIsFrozen] = React.useState(false);
    const [notification, setNotification] = React.useState({message: ''});

    return (
        <ProcessingContext.Provider value={ { isBusy, setIsBusy, isFrozen, setIsFrozen, notification, setNotification } }>
            { children }
        </ProcessingContext.Provider>
    );
}
