import React from 'react';
import { ActionMenuWrapper } from './actions-menu.styled';
import { useActionsMenuContext } from '../../contexts/actions-menu';
import ActionsMenuOpen from './buttons/open';
import ActionsMenuClose from './buttons/close';
import DownloadTrascript from './buttons/download-trascript';
// import ChangeLanguage from './buttons/change-language';

function ActionMenu() {
   const menuCtxt = useActionsMenuContext();

   return (
      <ActionMenuWrapper>
         {menuCtxt.isOpen
            ?  <div className='menu-pop-up'>
                  <ActionsMenuClose />
                  <DownloadTrascript />
                  {/* <ChangeLanguage /> */}
               </div>
            : null
         }

         <ActionsMenuOpen />
      </ActionMenuWrapper>
   )
};

export default ActionMenu;
